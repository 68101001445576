<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/countries' }">Countries</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/${$route.params.countryId}/regions` }">Regions</el-breadcrumb-item>
                <el-breadcrumb-item>Cities</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
  
            <h3
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Cities in {{ region.region_name ? region.region_name : '...' }} ( {{ region.country ? region.country : '...' }})</h3>
  
            <div class="search_add_section">
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by City"
                  class="search_by_input"
                  spellcheck="off"
                  style="width: 100%;"
                />
              </div>
              <div>
                <AddCityDialog @re-fresh="getCities()" :region="region"/>
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading || deleting"
              class="table-responsive"
            >
              <data-tables
                :data="cities"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getCities"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Cities Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Cities</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="50px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="City Name"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> <strong> </strong> {{ scope.row.city_name }} </span><br />
                  </template>
                </el-table-column>
                
                <el-table-column
                  label="Delivery Fee"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> <strong> </strong> {{ scope.row.delivery_fee }} </span><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          @click="showEditCityData(scope.row)"
                          type="warning"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          @click="deleteCity(scope.row.city_id)"
                          type="danger"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
      <EditCityDialog
        :showCityEditDialog="showCityEditDialog"
        :cityData="city_data"
        :region="region"
        @closeEditCityDialog="closeEditCityDialog"
        @re-fresh="getCities"
      />
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import AddCityDialog from "../components/dialogs/add-city-dialog.vue";
  import EditCityDialog from "../components/dialogs/edit-city-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      AddCityDialog,
      EditCityDialog
    },
  
    data() {
      return {
        isLoading: false,
        loading: false,
        loadingError: false,
        deleting: false,
        showCityEditDialog: false,
        city_data: {},
        region: {},
  
        Cities: [],
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "room_name",
            value: "",
          },
        ],
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getCities();
    },
  
    methods: {
      
      showEditCityData(cityData) {
        this.showCityEditDialog = true;
        this.city_data = cityData;
      },
  
      closeEditCityDialog() {
        this.showCityEditDialog = false;
      },
  
      async getCities() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`cities/${this.$route.params.regionId}`);
          if (request.data.success) {
            this.cities = request.data.cities;
            this.region = request.data.region;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Cities now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async switchStatus(package_id) {
        try {
          this.loading = true;
          let request = await this.$http.get(
            `cities/set_package_status?package_id=${package_id}`
          );
          if (request.data.success) {
            this.showSuccessMessage("Success", "Package status changed");
            this.loading = false;
            this.getCities();
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.loading = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change Package status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.loading = false;
        }
      },
  
      async deleteCity(id) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this City. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(
                `cities/${id}`
              );
              if (
                request.data.success &&
                request.data.message === "CITY_DELETED_SUCCESSFULLY"
              ) {
                this.getCities();
                this.$notify({
                  title: "Success",
                  message: "City deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete City, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  input:focus {
    border: 0.01em solid #409eff !important;
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  </style>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>