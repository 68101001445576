<template>
    <div>
    <el-button type="success"
        @click="showCityDialog"
      round>Add City</el-button>

      <article>
        <section>
          <div class="city_dialogs">
            <el-dialog
              :title="`Add City in ${region.region_name ? region.region_name : '...' } ( ${region.country ? region.country : '...' })`"
              :visible.sync="addCityDialog"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="cityForm"
                  :rules="rules"
                  label-position="top"
                  ref="cityForm"
                  class="demo-countryForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="City Name"
                        prop="city_name"
                      >
                        <el-input v-model="cityForm.city_name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Delivery Fee"
                        prop="delivery_fee"
                      >
                        <el-input v-model="cityForm.delivery_fee"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      type="success" round
                      @click="addCity('cityForm')"
                    >Add City in {{ region.region_name ? region.region_name : '...' }} ( {{ region.country ? region.country : '...' }})</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Everythingirly, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="cityForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        addCityDialog: false,
        submitting: false,
        cityForm: {
          city_name: "",
          delivery_fee: ""
        },
        rules: {
            city_name: [
                {
                required: true,
                message: "City Name is required",
                trigger: "blur",
                },
            ],
            delivery_fee: [
                {
                required: true,
                message: "Delivery fee is required",
                trigger: "blur",
                },
            ],
        },
      };
    },

    props: {
      region:{
        type: Object,
        required: true
      } 
    },

    methods: {
      handleClose(done) {
        done();
      },
      showCityDialog() {
        this.addCityDialog = true;
        this.cityForm.city_name = "";
      },
      async addCity(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.post(`cities`, {
                city_name: this.cityForm.city_name,
                delivery_fee: this.cityForm.delivery_fee,
                country_id: this.$route.params.countryId,
                region_id: this.$route.params.regionId,
                created_by: this.$store.state.userId,
              });
              if (
                response.data.success &&
                response.data.message == "CITY_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "City added successfully",
                  type: "success",
                });
                this.addCityDialog = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Add City",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>